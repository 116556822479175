/*==============================
Default CSS
==============================*/
:root {
    --fontFamily: 'Poppins', sans-serif;
    --mainColor: #cd061e;
    --whiteColor: #ffffff;
    --blackColor: #000000;
    --transition: .5s;
}
body {
    padding: 0;
    margin: 0;
    font-family: var(--fontFamily);
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	margin-bottom: 0;
    font-weight: 400;
    color: var(--blackColor);
	line-height: 1;
}
ul {
    padding-left: 0;
    margin-bottom: 0;

    li {
        list-style: none;
    }
}
:focus {
    outline: 0 !important;
}
p {
	margin-bottom: 0;
	line-height: 1.8;
	color: #727695;
}
a {
    cursor: pointer;
	color: #222222;
	text-decoration: none;
    transition: var(--transition);

    &:hover, &:focus {
        color: var(--mainColor);
	    text-decoration: none;
    }
}
img {
    max-width: 100%;
}
.ptb-150 {
    padding: {
        bottom: 150px;
        top: 150px;
    }
}
.pt-150 {
    padding: {
        top: 150px;
    }
}
.pb-150 {
    padding: {
        bottom: 150px;
    }
}
.ptb-100 {
    padding: {
        bottom: 100px;
        top: 100px;
    }
}
.pt-100 {
    padding: {
        top: 100px;
    }
}
.pb-100 {
    padding: {
        bottom: 100px;
    }
}
.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    }
}
.pt-70 {
    padding: {
        top: 70px;
    }
}
.pb-70 {
    padding: {
        bottom: 70px;
    }
}
.d-table {
	width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
.bg-fffdfd {
    background: #fffdfd;
}
.bg-F7F5F4 {
    background-color: #F7F5F4;
}
.ml-auto {
    margin-left: auto;
}
/*Btn Btn-Primary*/
.btn {
	padding: 10px 30px;
	border-radius: 30px;
    transition: var(--transition);
    font: {
        size: 16px;
        weight: 500;
    };
}
.btn-primary {
	color: var(--whiteColor);
	background-color: var(--mainColor);
    border-color: var(--mainColor);

    &:hover, &:focus, &.disabled:hover, &.disabled:focus {
        color: var(--mainColor);
	    background-color: var(--whiteColor);
	    border-color: var(--mainColor);
    }
    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
        color: var(--whiteColor);
	    background-color: var(--mainColor);
	    border-color: var(--mainColor);
    }
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
    }
    &.focus, &:focus {
        box-shadow: none;
    }
    &.disabled, &:disabled {
        color: var(--whiteColor);
        background-color: var(--mainColor);
        border-color: var(--mainColor);
        opacity: 1;
    }
}

/* Section Title */
.section-title {
	margin-bottom: 60px;
	padding-left: 30px;
    position: relative;

    &::before, &::after {
        content: '';
        position: absolute;
        width: 3px;
        height: 100%;
        left: 10px;
        top: 0;
        background: var(--mainColor);
        transition: var(--transition);
    }
    &::after {
        left: 0;
        height: 50%;
    }
    &:hover::before {
        height: 50%;
    }
    &:hover::after {
        height: 100%;
    }
    h2 {
        margin-bottom: 20px;
        font: {
            size: 40px;
            weight: 400;
        }
        span {
            color: var(--mainColor);
            font-weight: 600;
        }
    }
    p {
        max-width: 635px;
        margin-top: -7px;
    }
    h4,
    .title {
        color: var(--mainColor);
        margin-bottom: 14px;
        text-transform: capitalize;
        font: {
            size: 17px;
            weight: 400;
        }
    }
}

/* Form Control */
.form-control {
	height: 45px;
	padding: 10px;
	font-size: 15px;
	background-color: var(--whiteColor);
	border: 1px solid #eeeeee;
    border-radius: 0;

    &:focus {
        border-color: #dddddd;
	    outline: 0;
	    box-shadow: none;
    }
}
textarea.form-control {
    height: auto;
}

/* The container */
.form-group-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.form-group-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 1px solid #eeeeee;
}

/* On mouse-over, add a grey background color */
.form-group-container:hover input ~ .checkmark {
    border-color: #cd061e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.form-group-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.form-group-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #cd061e;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


/* Shape */
.shape1 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.shape2 {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}
.shape3 {
    position: absolute;
    right: 50px;
    bottom: 0;
}

.data-privacy-area-title {
    margin-top: 24px;
    margin-bottom: 16px;
}
