/* You can add global styles to this file, and also import other style files */

@import './styles/basic';
@import './styles/mainHeader';
@import './styles/mainBanner';
@import './styles/welcome';
@import './styles/advantages';
@import './styles/teams';
@import './styles/services';
@import './styles/offers';
@import './styles/competence';
@import './styles/contact';
@import './styles/footer';
@import './styles/subfooter';
@import './styles/navbar';
@import "./styles/cookieBanner";

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
