/*==============================
Team CSS
==============================*/
.who-we-are-area {
    position: relative;
    z-index: 1;
    background: {
        position: center center;
    };
}
.single-who-we-are {
    margin-bottom: 30px;
	position: relative;
    z-index: 1;
    background: var(--whiteColor);
    border: 1.5px dashed #eeeeee;
	padding: 30px;
    transition: var(--transition);
    height: calc(100% - 30px);

    i {
        font-size: 50px;
        color: var(--mainColor);
        transition: var(--transition);
    }
    h3 {
        transition: var(--transition);
        text-transform: capitalize;
        margin: {
            bottom: 15px;
            top: 23px;
        };
        font: {
            size: 20px;
            weight: 600;
        };
    }
    p {
        font-size: 15.5px;
        transition: var(--transition);
    }
    span {
        bottom: 0;
        color: var(--blackColor);
        opacity: .02;
        font-size: 200px;
        line-height: 180px;
        position: absolute;
        right: 0;
        transition: var(--transition);
    }
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0;
        width: 100%;
        transition: var(--transition);
        background: var(--mainColor);
        z-index: -1;
    }
    &:hover {
        box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
        color: var(--whiteColor);
        transform: translateY(-10px);

        i {
            color: var(--whiteColor);
        }
        p {
            color: var(--whiteColor);
            opacity: 0.9;
        }
        h3 {
            color: var(--whiteColor);
        }
        span {
            color: var(--whiteColor);
            opacity: 0.2;
        }
        &::before {
            height: 100%;
        }
    }
}