/*==============================
Footer CSS
==============================*/
.footer-area {
    padding: 50px 0;
    background: #2c2c2c;
    font-size: 13px;

    i {
        margin-right: 13px;
    }

    img {
        height: 75px;
        width: 250px;
        margin-bottom: 30px;
    }

    h4 {
        color: var(--whiteColor);
        margin-bottom: 30px;
    }

    p {
        line-height: 1;
        margin-bottom: 20px;

        color: var(--whiteColor);

        a {
            color: var(--mainColor);
            font-weight: 600;

            &:hover {
                color: var(--whiteColor);
            }
        }
    }
}
