/*==============================
Competence CSS
==============================*/
.faq-image {
	width: 100%;
    height: 100%;
    img {
        display: none;
    }
}
.faq-accordion {
    .accordion {
        .accordion-section {
            border-radius: 5px;
            margin-bottom: 15px;
            background-color: var(--whiteColor);
            box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13);

            .accordion-header {
                display: block;
                cursor: pointer;
                position: relative;
                color: var(--blackColor);
                padding: 20px 40px 20px 20px;
                background: var(--whiteColor);
                transition: var(--transition);
                font: {
                    size: 16px;
                    weight: 500;
                };
                &:hover, &.open {
                    color: var(--whiteColor);
                    background: var(--mainColor);
                }
                &::before {
                    top: 50%;
                    right: 20px;
                    font-size: 16px;
                    content: "\f078";
                    position: absolute;
                    transform: translateY(-50%);
                    font: {
                        family: Font Awesome\ 5 Free;
                        weight: 900;
                    };
                }
            }
            .accordion-content {
                display: none;
                padding: 20px;

                &.open {
                    display: block;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
