/*==============================
Why We Are Different CSS
==============================*/
.why-we-different-tabset {
    &.tabset {
        input[type="radio"] {
            display: none;
        }
        .tab-panel {
            display: none;
        }
        input {
            &:first-child:checked ~ .tab-panels {
                .tab-panel {
                    &:first-child {
                        display: block;
                    }
                }
            }
            &:nth-child(3):checked ~ .tab-panels {
                .tab-panel {
                    &:nth-child(2) {
                        display: block;
                    }
                }
            }
            &:nth-child(5):checked ~ .tab-panels {
                .tab-panel {
                    &:nth-child(3) {
                        display: block;
                    }
                }
            }
            &:nth-child(7):checked ~ .tab-panels {
                .tab-panel {
                    &:nth-child(4) {
                        display: block;
                    }
                }
            }
            &:nth-child(9):checked ~ .tab-panels {
                .tab-panel {
                    &:nth-child(5) {
                        display: block;
                    }
                }
            }
            &:nth-child(11):checked ~ .tab-panels {
                .tab-panel {
                    &:nth-child(6) {
                        display: block;
                    }
                }
            }
        }
        label {
            position: relative;
            display: inline-block;
            padding: 15px 32px;
            cursor: pointer;
            background: transparent;
            transition: var(--transition);
            border: 1px solid #dddddd;
            border-radius: 5px;
            font: {
                size: 18px;
                weight: 500;
            };
            margin: {
                right: 10px;
                bottom: 0;
            };
        }
        input:checked + label {
            border-color: var(--mainColor);
            background: var(--mainColor);
            color: var(--whiteColor);
        }
    }
    .tab-panel {
        margin-top: 35px;
    }
}
.why-we-different-content {
    padding-left: 10px;

    h3 {
        margin-bottom: 15px;
        font: {
            size: 25px;
            weight: 500;
        };
    }
    ul {
        margin: {
            top: 20px;
            bottom: 0;
        };
        li {
            margin-bottom: 10px;
            font-weight: 300;
            color: #727695;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                color: var(--mainColor);
                margin-right: 5px;
            }
        }
    }
}
.why-we-different-img {
    text-align: center;

    img {
        height: 400px;
        width: 600px;
        object-fit: cover;
    }
}
