/*==============================
Advantages CSS
==============================*/
.strategy-area {
    position: relative;
    z-index: 1;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
        .row {
            margin: {
                left: 0;
                right: 0;
            };
            .col-lg-6 {
                padding: {
                    left: 0;
                    right: 0;
                };
            }
        }
    }
}
.strategy-image {
    width: 100%;
    height: 100%;
    background: {
        image: url(../assets/img/vorteile.webp);
        size: cover;
        position: center center;
        repeat: no-repeat;
    };
    img {
        display: none;
    }
}
.strategy-content {
    padding-left: 35px;

    .section-title {
        margin-bottom: 20px;
        padding-left: 0;

        &::before, &::after {
            display: none;
        }
        p {
            font-size: 15.5px;
        }
    }
    .features-list {
        margin-bottom: 20px;

        li {
            margin-bottom: 10px;
            color: #727695;
            font: {
                size: 15.5px;
            };
            i {
                color: var(--mainColor);
                margin-right: 7px;
            }
        }
    }
    p {
        max-width: 635px;
    }
}
