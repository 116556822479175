/*==============================
Main Header CSS
==============================*/
.navbar {
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	position: fixed !important;
	background: transparent !important;
    padding: {
        top: 30px;
        bottom: 30px;
    };
    &.sticky {
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        position: fixed !important;
        background: var(--whiteColor) !important;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        padding: {
            top: 20px;
            bottom: 20px;
        };
    }
}
.navbar-light {
    .navbar-brand {
        color: var(--whiteColor);
        text-transform: uppercase;
        line-height: 10px;
        transition: var(--transition);
        font: {
            size: 23px;
            weight: 600;
        }
        span {
            color: var(--mainColor);
            line-height: 10px;
            text-transform: lowercase;
            transition: var(--transition);
            font: {
                size: 40px;
                weight: 700;
            }
        }
        &:focus, &:hover {
            color: var(--whiteColor);
        }
    }
    .navbar-nav {
        .nav-item {
            padding: {
                right: 15px;
                left: 15px;
                top: 0;
                bottom: 0;
            };
            &:last-child {
                padding-right: 0;
            }
            &:first-child {
                padding-left: 0;
            }
            .nav-link {
                position: relative;
                cursor: pointer;
                font-weight: 500;
                transition: var(--transition);
                padding: {
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                };
                &.subpage {
                    color: var(--blackColor) !important;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    background-color: var(--mainColor);
                    transition: var(--transition);
                    transform: scaleX(0);
                }
                &.active, &:hover, &:focus {
                    color: var(--mainColor);

                    &::before {
                        transform: scaleX(1);
                    }
                }
            }
        }
        &.for-responsive {
            display: none;
        }
    }
    &.sticky {
        .navbar-brand {
            color: var(--blackColor);
        }
        .navbar-nav {
            .nav-link {
                color: var(--blackColor);

                &.active, &.show, &:hover, &:focus {
                    color: var(--mainColor);
                }
            }
            .active, .show {
                .nav-link {
                    color: var(--mainColor);
                }
            }
        }
    }
}
// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {

    .navbar {
        padding: {
            top: 15px;
            bottom: 15px;
        };
    }
    .navbar-light {
        .navbar-nav {
            background-color: var(--whiteColor);
            padding: 10px 20px;
            margin-top: 10px;
            display: none;

            .nav-item {
                padding: {
                    left: 0;
                    right: 0;
                    top: 10px;
                    bottom: 10px;
                };
                .nav-link {
                    color: var(--blackColor);
                    display: inline-block;

                    &.active, &:hover {
                        color: var(--mainColor);
                    }
                }
            }
            &.for-responsive {
                display: block !important;
            }
        }
        .navbar-toggler {
            color: var(--blackColor);
            font-size: inherit;
            box-shadow: unset;
            border: none;
            padding: 0;

            .burger-menu {
                cursor: pointer;

                span {
                    height: 2px;
                    width: 30px;
                    margin: 6px 0;
                    display: block;
                    background: var(--blackColor);
                }
            }
        }
        &.active {
            .navbar-toggler {
                .burger-menu {
                    span {
                        &.top-bar {
                            transform: rotate(45deg);
                            transform-origin: 10% 10%;
                        }
                        &.middle-bar {
                            opacity: 0;
                        }
                        &.bottom-bar {
                            transform: rotate(-45deg);
                            transform-origin: 10% 90%;
                            margin-top: 5px;
                        }
                    }
                }
            }
            .collapse:not(.show) {
                display: block;
            }
        }
        &.sticky {
            .navbar-nav {
                border: 1px solid #eeeeee;
            }
            .navbar-toggler {
                color: var(--blackColor);

                .burger-menu {
                    span {
                        background: var(--blackColor);
                    }
                }
            }
        }
    }

}
