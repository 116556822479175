/*==============================
Cookie Banner CSS
==============================*/
.nas-cookie-banner {
    position: fixed;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    z-index: 9999;
    padding: 16px;
    left: 0;
    right: 0;
    bottom: 0;
    color: rgb(255, 255, 255);
    background-color: rgb(205, 6, 30);
    opacity: 1;
    transition: opacity 1s ease;
    &.hidden {
        opacity: 0;
    }
}

.nas-cookie-banner-change-choice {
    position: fixed;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    z-index: 9998;
    padding: 16px;
    left: 0;
    right: 0;
    bottom: -20px;
    color: rgb(255, 255, 255);
    background-color: rgb(205, 6, 30);
    opacity: 1;
    transition: bottom 1s ease, opacity 1s ease;
    border-radius: 4px 4px 0 0;
    width: fit-content;
    &:hover {
        bottom: 0;
    }
}
