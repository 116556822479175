/*==============================
Contact CSS
==============================*/
#map {
    width: 100%;
    height: 370px;
}

.contact-info {
    box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
    background: var(--whiteColor);
    padding: 30px;
    height: 100%;
    border: {
        top: 3px solid var(--mainColor);
        bottom: 3px solid var(--mainColor);
    };

    ul {
        li {
            position: relative;
            padding-left: 60px;
            font-size: 16px;
            margin-bottom: 34px;
            color: var(--blackColor);

            &:last-child {
                margin-bottom: 0;
            }

            a {
                display: inline-block;
            }

            span {
                display: block;
                color: var(--blackColor);
                margin-bottom: 5px;
                font: {
                    size: 20px;
                    weight: 500;
                };
            }

            i {
                position: absolute;
                left: 0;
                top: 50%;
                color: var(--mainColor);
                font-size: 19px;
                background: #f6f6f6;
                width: 45px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                border-radius: 50%;
                transition: var(--transition);
                transform: translateY(-50%);
            }

            &:hover {
                i {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
}

.contact-form {
    background: var(--whiteColor);
    box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
    padding: 30px;
    border: {
        top: 3px solid var(--mainColor);
        bottom: 3px solid var(--mainColor);
    };

    h3 {
        color: var(--mainColor);
        margin-bottom: 25px;
        font: {
            size: 20px;
            weight: 600;
        };
    }

    .form-control {
        border-radius: 3px;

        &:focus {
            border-color: var(--mainColor);
        }
    }

    label {
        margin-bottom: .5rem;
    }

    .btn {
        margin-top: 15px;
    }

    #message {
        height: auto;
        padding: 15px;
    }
}

.mail-response {
    p, i {
        color: green;
    }

    &.error {
        p, i {
            color: #cd061e;
        }
    }
}
