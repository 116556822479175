/*==============================
Main Banner CSS
==============================*/
.main-banner {
	height: 850px;
	position: relative;
    overflow: hidden;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--blackColor);
        opacity: .69;
    }
    &.gradient-bg {
        &::before {
            background: linear-gradient(90deg, #232021 0, #0093c8);
            opacity: .80;
        }
    }

    a {
        color: var(--whiteColor);
        font-weight: 600;

        &:hover {
            color: var(--mainColor);
        }
    }
}
.item-bg-one {
	background-image: url(../assets/img/zahnrad.webp);
}
.main-banner-text {
    position: relative;
    text-align: center;
    z-index: 1;

    .title {
        color: var(--whiteColor);
        font: {
            weight: 400;
            size: 20px;
        };
    }
    h1 {
        color: var(--whiteColor);
        margin: {
            top: 30px;
            bottom: 30px;
        };
        font: {
            weight: 400;
            size: 72px;
        };
        span {
            color: var(--mainColor);
            font-weight: 600;
        }
        ngx-typed-js {
            display: inline-block;

            div {
                display: inline-block;

                span {
                    display: inline-block;
                }
            }
        }
    }
    p {
        color: var(--whiteColor);
        max-width: 650px;
        font-size: 15.5px;
        margin: {
            left: auto;
            right: auto;
            bottom: 30px;
        };
    }
    .btn {
        margin: {
            left: 7px;
            right: 7px;
        };
        &:hover, &:focus {
            border-color: var(--whiteColor);
        }
    }
    .view-work {
        background: transparent;
        border-color: var(--whiteColor);

        &:hover, &:focus {
            border-color: var(--mainColor);
            background: var(--mainColor);
            color: var(--whiteColor);
        }
    }
    &.text-start {
        p {
            margin: {
                left: 0;
                right: 0;
            };
        }
    }
    &.text-end {
        p {
            margin: {
                left: auto;
                right: 0;
            };
        }
    }
}
.home-slides {
    .owl-theme {
        .owl-nav {
            margin-top: 0;
            transition: var(--transition);
            opacity: 0;
            visibility: hidden;

            [class*="owl-"] {
                position: absolute;
                left: 40px;
                top: 50%;
                transform: translateY(-50%);
                background: rgba(255,255,255,0.33);
                width: 55px;
                margin: 0;
                padding: 0;
                text-align: center;
                height: 55px;
                line-height: 58px;
                border-radius: 50%;
                font-size: 25px;
                color: var(--whiteColor);
                transition: var(--transition);

                &.owl-next {
                    left: auto;
                    right: 40px;
                }
                &:hover {
                    background: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
