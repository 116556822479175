/*==============================
Footer CSS
==============================*/
.subfooter-area {
    padding: 25px 0;
    background: #1f1f1f;
    font-size: 13px;

    p {
        color: var(--whiteColor);
    }

    .link-area {
        text-align: right;
    }

    a {
        cursor: pointer;
        color: var(--mainColor);
        text-decoration: none;
        transition: var(--transition);

        &:hover, &:focus {
            color: var(--whiteColor);
            text-decoration: none;
        }
    }

}
