/*==============================
Offers SCSS
==============================*/
.pricing-area {
    position: relative;
    z-index: 1;
}
.pricingTable {
    margin-bottom: 30px;
    background-color: var(--whiteColor);
	box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
	text-align: center;
    transition: var(--transition);
	position: relative;
    background: {
        image: url(../assets/img/patt.png);
        position: center center;
    };
    padding: {
        bottom: 30px;
    };
    height: calc(100% - 60px);
    &::after {
        content: "";
        width: 70px;
        height: 30px;
        background: var(--mainColor);
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        margin: {
            left: auto;
            right: auto;
        };
    }
    .title {
        margin-bottom: 35px;
        background: var(--mainColor);
        color: var(--whiteColor);
        text-transform: uppercase;
        padding: {
            top: 20px;
            bottom: 20px;
        };
        font: {
            weight: 500;
            size: 24px;
        };
    }
    .price-value {
        margin-bottom: 25px;
        color: var(--blackColor);

        .amount {
            display: inline-block;
            font: {
                size: 44px;
                weight: 600;
            };
        }
        .month {
            display: block;
            line-height: 16px;
            text-transform: capitalize;
            color: #727695;
            margin-left: 50px;
            font: {
                size: 15px;
                weight: 400;
            };
        }
    }
    .currency {
        display: inline-block;
        font-size: 25px;
        vertical-align: top;
        margin-top: 6px;
    }
    .pricing-content {
        margin-bottom: 30px;
        border: {
            top: 1px solid #eeeeee;
        };
        padding: {
            top: 25px;
            bottom: 25px;
        };
        li {
            color: #727695;
            margin-top: 16px;

            &:first-child {
                margin-top: 0;
            }
            i {
                color: var(--mainColor);
                margin-right: 4px;
            }
        }
    }
    &:hover {
        transform: translateY(-10px);
    }
}

.footnote {
    color: var(--mainColor);
}
