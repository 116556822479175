/*==============================
Welcome CSS
==============================*/
.welcome-area {
    position: relative;
    z-index: 1;
}
.single-box {
	text-align: center;
	box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
	padding: 30px 20px;
    position: relative;
    overflow: hidden;
    transition: var(--transition);
    margin-bottom: 30px;

    .icon {
        color: var(--mainColor);
        font-size: 25px;
        width: 65px;
        border: 1px dashed var(--mainColor);
        height: 65px;
        position: relative;
        border-radius: 3px;
        transition: var(--transition);
        margin: {
            left: auto;
            right: auto;
        };
        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    h3 {
        transition: var(--transition);
        margin: {
            top: 25px;
            bottom: 12px;
        }
        font: {
            weight: 600;
            size: 20px;
        }
    }
    p {
        transition: var(--transition);
        font-size: 15.5px;
    }
    .link-btn {
        position: absolute;
        right: -20px;
        bottom: 0;
        width: 45px;
        height: 40px;
        line-height: 44px;
        text-align: center;
        box-shadow: 0 0 25px 0 rgba(0, 27, 103, 0.2);
        border-radius: 50% 0 0 0;
        opacity: 0;
        visibility: hidden;
    }
    &:hover {
        transform: translateY(-5px);
        background-color: var(--mainColor);

        .icon {
            background-color: var(--whiteColor);
            border-color: var(--whiteColor);
        }
        h3 {
            color: var(--whiteColor);
        }
        p {
            color: var(--whiteColor);
        }
        .link-btn {
            background: var(--whiteColor);
            color: var(--mainColor);
            opacity: 1;
            visibility: visible;
            right: 0;
        }
    }
}

.welcome-text {
    position: relative;
    img {
        height: 400px;
        width: 600px;
        object-fit: cover;
    }
    &-container {
        margin-top: 48px;
        > div {

        }
    }
}
